import { Box, desktopFirstMediaQuery, Title } from '@inflearn/ds-react';
import { map } from 'lodash-es';
import { SessionPageContext } from '../../../../pages/infcon-2023/session';
import { SESSION_TYPE } from '../../commons/constants';
import SessionTable, { type SessionTableRowType } from '../Table';
import { styleRowDivider, styleSessionTableSection } from '../Table/styles';

type SessionMyScheduleProps = {
  sessions: SessionTableRowType[];
};

const SessionMySchedule = ({ sessions }: SessionMyScheduleProps) => {
  return (
    <SessionPageContext.Provider value={{ type: SESSION_TYPE.speaker, sessions }}>
      <Box
        component="section"
        css={[
          styleSessionTableSection,
          { [desktopFirstMediaQuery.mobile]: { padding: '0 0 100px 0' } }
        ]}>
        <Title order={3} className="visually-hidden">
          내 일정 시간표
        </Title>
        <Box component="ul" css={{ width: '100%', li: { width: '100%' } }}>
          <Box component="li">
            <SessionTable.Header names={['시간', '장소', '발표자', '세션명']} />
          </Box>
          {map(sessions, (row) => {
            if ('type' in row && row.type === 'speaker') {
              return (
                <Box component="li" key={row.startedAt} css={styleRowDivider}>
                  <SessionTable.TimeRow
                    startedAt={row.startedAt}
                    endedAt={row.endedAt}
                    speakerRows={row.speakerRows}
                    offsetTop={{ default: '142px', mobile: '132px' }}
                  />
                </Box>
              );
            }
          })}
        </Box>
      </Box>
    </SessionPageContext.Provider>
  );
};

export default SessionMySchedule;
