import { useMutation } from 'react-query';
import { fetcher, logger } from '../utils/Fetcher';
import { API_URLS } from '../utils/urls';
import { type AntmanErrorType } from '../@types/schemas';

type ShareMyScheduleResponse = {
  ok: true;
  url: string;
};

const useShareMySchedule = (year: number = 2023) => {
  return useMutation('share-my-schedule', async () => {
    try {
      const response = await fetcher.get<ShareMyScheduleResponse | AntmanErrorType>(
        API_URLS.SHARE_MY_SCHEDULE(year)
      );

      if (!response.ok) {
        throw new Error(response.err.msg);
      }

      return response;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  });
};

export default useShareMySchedule;
