import { css } from '@emotion/react';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import { Button, Icon, Modal, TextInput, Tooltip } from '@inflearn/ds-react';
import { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { type Schedule } from '../../../dtos/ShareScheduleDto';
import useShareMySchedule from '../../../hooks/useShareMySchedule';
import { GTMScheduleLinkCopyClick } from '../../../utils/GTM';
import ShareTable from '../share/ShareTable';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  data: Schedule[][];
};

const ShareScheduleModal = ({ isOpen, onClose, data }: Props) => {
  const { mutateAsync: shareMySchedule } = useShareMySchedule();
  const [shareUrl, setShareUrl] = useState('');
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    void updateShareUrl();
  }, [isOpen]);

  const updateShareUrl = async () => {
    const data = await shareMySchedule();

    setShareUrl(data.url);
  };

  return (
    <Modal.Root opened={isOpen} onClose={onClose} size="100%" id="share-schedule">
      <Modal.Overlay />
      <Modal.Content css={css({ maxWidth: '502px' })}>
        <Modal.Header css={css({ paddingBottom: '16px' })}>
          <Modal.Title>내 시간표 공유</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body css={{ marginBottom: '16px' }}>
          <ShareTable schedulesTable={data} />
        </Modal.Body>
        <Modal.Footer noWrap spacing={8}>
          <TextInput value={shareUrl} size="md" disabled css={{ width: '100%' }} />
          <CopyToClipboard
            text={shareUrl}
            onCopy={() => {
              setIsTooltipOpen(true);
              GTMScheduleLinkCopyClick();
              setTimeout(() => {
                setIsTooltipOpen(false);
              }, 1500);
            }}>
            <Tooltip
              label="링크 복사 완료!"
              color="black"
              opened={isTooltipOpen}
              position="top-end"
              withArrow>
              <Button
                leftIcon={<Icon icon={faLink} />}
                size="md"
                color="dark"
                disabled={shareUrl === ''}>
                공유
              </Button>
            </Tooltip>
          </CopyToClipboard>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};

export default ShareScheduleModal;
